<template>
  <div class="mainPolicyContainer">
  <div class="headingContainer headingCenter"><span class="heading"> Refund Policy </span></div>
  <ol>
<li> We suggest you to evaluate APIBridge in free paper trading mode before making a purchase. See Detailed Documentation & Videos <a target="_blank" href="https://algoji.com/apibridge-documentation">here</a> . Personal Demo is not available, you can see videos with live trading on above documentation page.</li>
<li>Your APIBridge subscription will be activated immediately and automatically; and, you will receive the subscription details over email. Hence, we cannot refund your money once order is placed. For Algoji club membership, you will receive a welcome call within 2 working days.</li>
<li>Pricing plans are for APIBridge application only. Any plan does not include personal strategy consultation; custom strategy integration or strategy configuration.</li>
<li>If you want to trade based on calls from an Investment Advisor, you need to purchase APIBridge separately from the particular Investment Advisor</li>
<li>AlgoJi Club Membership: Its a closed and trusted slack group for resource sharing. Club Membership is provided free with APIBridge annual plan. Algoji reserves the right to expel any member from slack group who does not behave in a favorable manner with other members.</li>
</ol>
</div>
</template>
<script>
import "../assets/css/usage-policy.css";
export default {

}
</script>

<style scoped>
.mainPolicyContainer{
    min-height: 100vh;
    font-weight: 400;
}
body{
    /* background-color:#fff ; */
    color: #000;
    margin: auto;
    font-weight: 300;
    margin: 0 30px;
    
}
.usage
ol{
    margin: 0px 30px;
    font-family: Arial,serif;
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
}

li{
    margin-bottom: 20px;
}

p{
    font-weight: 400;
}
</style>